exports.components = {
  "component---src-pages-404-en-tsx": () => import("./../../../src/pages/404.en.tsx" /* webpackChunkName: "component---src-pages-404-en-tsx" */),
  "component---src-pages-doodle-en-tsx": () => import("./../../../src/pages/doodle.en.tsx" /* webpackChunkName: "component---src-pages-doodle-en-tsx" */),
  "component---src-pages-doodle-it-tsx": () => import("./../../../src/pages/doodle.it.tsx" /* webpackChunkName: "component---src-pages-doodle-it-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-it-tsx": () => import("./../../../src/pages/index.it.tsx" /* webpackChunkName: "component---src-pages-index-it-tsx" */),
  "component---src-pages-privacy-en-tsx": () => import("./../../../src/pages/privacy.en.tsx" /* webpackChunkName: "component---src-pages-privacy-en-tsx" */),
  "component---src-pages-privacy-it-tsx": () => import("./../../../src/pages/privacy.it.tsx" /* webpackChunkName: "component---src-pages-privacy-it-tsx" */),
  "component---src-pages-terms-en-tsx": () => import("./../../../src/pages/terms.en.tsx" /* webpackChunkName: "component---src-pages-terms-en-tsx" */),
  "component---src-pages-terms-it-tsx": () => import("./../../../src/pages/terms.it.tsx" /* webpackChunkName: "component---src-pages-terms-it-tsx" */)
}

